<script>
    import {Datetime} from 'vue-datetime';

    export default {
        name: "DateTimePicker",
        extends: Datetime,
        props: {
            type: {
                type: String,
                default: 'datetime'
            },
            zone: {
                type: String,
                default: 'Europe/Paris'
            },
            valueZone: {
                type: String,
                default: 'Europe/Paris'
            },
            phrases: {
                type: Object,
                default() {
                    return {
                        cancel: 'Annuler',
                        ok: 'Ok'
                    }
                }
            },
            // Format is Luxon format, see https://moment.github.io/luxon/docs/manual/formatting.html#table-of-tokens
            format: {type: String, default: 'yyyy-LL-dd HH:mm'},
        }
    }
</script>